.shelf {
    .shelfParent {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 10px;
        /* padding-top: 10px; */
        /* padding-bottom: 10px; */
        height: fit-content;
        margin: 10px;
        /* outline: double; */
        background-color: white;
    }

    .rowParent {
        position: relative;
        display: flex;
        gap: 2px;
        height: 90px;
        align-items: end;
        border-bottom: 10px solid #c0c0c0;
        /* background-color: dimgray; */
    }

    .newProductParent {
        width: 100%;
        max-width: 350px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        padding: 0 12px;
        max-height: 700px;
        overflow-y: scroll;
    }

    .newProductChild {
        width: calc(33.33333% - 16px);
        border: none;
        background: none;
        position: relative;
    }

    /* .showWidth {
        height: 50px;
        border: 2px solid black;
        border-top: none;
        margin-left: 10px;
      } */

    .rowHeight {
        border: 1px solid black;
        border-left: none;
        width: 25px;
    }

    .pdfBtn,
    .btnExport {
        padding: 10px 20px;
        font-size: 18px;
        font-weight: bold;
        border: none;
        background-color: steelblue;
        color: white;
        margin-bottom: 10px;
        border-radius: 5px;
    }

    // .login_btn {
    //     border: none;
    //     padding: 10px 50px;
    //     font-weight: 600;
    //     font-size: 16px;
    //     background-color: #4272d7;
    //     color: white;
    //     border-radius: 25px;
    // }

    // .login_parent {
    //     width: 400px;
    //     background-color: #fff;
    //     text-align: center;
    //     margin: auto;
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     display: flex;
    //     flex-direction: column;
    //     gap: 20px;
    //     padding: 100px 30px;
    //     box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    //     border-radius: 5px;
    // }

    // .input_login {
    //     height: 50px;
    //     width: 100%;
    //     border: none;
    // }

    .btnsParent {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin-top: 25px;
    }

    .popup_delete,
    .popup_close {
        border: none;
        padding: 5px 10px;
    }

    .rowParent img:hover {
        cursor: pointer;
    }

    .outofstock {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    .undoredo {
        border: none;
        padding: 10px 50px;
        font-weight: 600;
        background-color: steelblue;
        color: white;
        border-radius: 5px;
    }
}