.newshelf {
    .header {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        border-top: 1px solid #919191;
        border-bottom: 1px solid #919191;
        background-color: #EEEEEE;
        color: black;
    }

    .brand {
        padding: 15px;
        background-color: #ffffff;
    }

    .listparent {
        display: flex;
        column-gap: 20px;
        margin-top: 12px;
        margin-bottom: 12px;
        padding-right: 25px;
    }

    .listparent li {
        list-style-type: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        column-gap: 8px;
    }

    .productlistParent {
        width: 20%;
        border-right: 1px solid #919191;
        height: calc(100vh - 80px);
        overflow-y: scroll;
    }

    .productshelfParent {
        width: 80%;
        padding: 15px;
        background-color: #F2F6FA;
    }

    .producttemplateParent,
    .productInformationParent {
        background-color: #EEEEEE;
        padding: 15px;
    }

    .content {
        display: flex;
        width: 100%;
    }

    .content .categorytitle {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .productlistParent .search,
    .productlistParent .search:focus-visible {
        outline: none;
        padding: 8px;
        width: 90%;
    }

    .shelflistParent {
        display: flex;
        border: 1px solid #717171;
        align-items: center;
        padding: 10px;
        column-gap: 25px;
    }

    .shelflistParent p {
        margin-bottom: 5px;
        font-size: 12px;
    }

    .shelflistParent h6 {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .shelflistWrapper {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        padding: 15px;
    }

    .accordionParent {
        background-color: #ffffff !important;
        box-shadow: none !important;
    }

    .accordionParent.active {
        background-color: #EEF3F8 !important;

    }

    .accordionContent {
        background-color: #F6F6F6;
    }

    .searchWrapper {
        padding-left: 15px;
        padding-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        border-bottom: 1px solid #C2C2C2;
        position: relative;
        height: 65px;
    }

    .selected {
        color: #0EA31D;
        border: 1px solid #0EA31D;
        padding: 5px 10px;
    }

    .selectedParent {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .shelfDetails {
        width: 100%;
    }

    .imageWrapper {
        width: 100%;
        height: 233px;
        border: 1px solid #2B2B2B
    }

    .selectedImage {
        width: 100%;
    }

    .selectedImageDetails p {
        margin-bottom: 8px;
        font-size: 14px;
    }

    .selectedImageDetails {
        border: 1px solid #717171;
        background-color: #FFFFFF;
        padding: 15px;
        margin-top: 20px;
    }

    .productInformationButtonWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    .removebuton,
    .closebutton {
        border: 1px solid #919191;
        padding: 10px 60px;
        background-color: #d7d7d7;
    }

    .shelfImageWrapper {
        text-align: center;
    }

    .newProductsImageWrapper {
        position: relative;
    }

    .count {
        position: absolute;
        padding: 5px;
        background-color: #F20707;
        border-radius: 100%;
        margin-top: -10px;
        margin-left: -10px;
        font-size: 10px;
        color: #FFFFFF;
        top: 5px;
        right: -15px;
        width: 22px;
        height: 22px;
    }

    .accordionContent .imageParent {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 25px;
        row-gap: 15px;
        justify-content: center;
    }

    .convertpdf {
        border: 1px solid #0570EB;
        color: #0570EB;
        font-size: 16px;
        padding: 10px 20px 10px 20px;
        border-radius: 5px;
    }

    .exportjson {
        background-color: #0570EB;
        padding: 10px 20px 10px 20px;
        font-size: 16px;
        color: #FFFFFF;
        border-radius: 5px;
    }

    .input-box {
        position: absolute;
        width: 100%;
        max-width: 15px;
        height: 55px;
        margin: 0 50px;
        background-color: #fff;
        border-radius: 6px;
        transition: all 0.5s ease-in-out;
        right: 0;
    }

    .input-box.open {
        max-width: 325px;
    }

    .input-box input {
        position: relative;
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: 400;
        color: #333;
        padding: 0 15px;
        border: none;
        border-radius: 6px;
        outline: none;
        transition: all 0.5s ease-in-out;
    }

    .input-box.open input {
        padding: 0 15px 0 65px;
    }

    .input-box .searchImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        cursor: pointer;
    }

    .input-box.open .searchImage {
        border-radius: 6px 0 0 6px;
    }

    .searchImage .searchImage-icon {
        font-size: 30px;
        color: #1c1c1c;
        height: 30px;
        width: 30px;
        object-fit: contain;
    }

    .input-box .close-icon {
        position: absolute;
        top: 50%;
        right: -45px;
        font-size: 30px;
        color: #1c1c1c;
        padding: 5px;
        transform: translateY(-50%);
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        pointer-events: none;
        opacity: 0;
    }

    .input-box.open .close-icon {
        transform: translateY(-50%) rotate(180deg);
        pointer-events: auto;
        opacity: 1;
    }

    .newProductsImage {
        height: 80px;
        width: 80px;
    }

    .accordionTitle {
        font-size: 20px;
        margin-bottom: 5px;
    }

    .accordionTitle .isOpened {
        background: #F20707;
        color: white;
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 12px;
    }

    .accordionTitle .isNotOpened {
        background: #FAD4D4;
        color: #F20707;
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 12px;
    }

    .newProductsImageWrapper {
        width: 80px;
        height: 80px;

        button {
            border: none;
        }
    }

    .outofstock {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    .shelfParent {
        display: flex;
        flex-direction: column-reverse;
        height: fit-content;
        margin-top: 10px;
        background-color: white;
        width: fit-content;
        margin-bottom: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    .rowParent {
        position: relative;
        display: flex;
        gap: 2px;
        height: 90px;
        align-items: end;
    }

    .newProductParent {
        width: 100%;
        max-width: 350px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        padding: 0 12px;
        max-height: 700px;
        overflow-y: scroll;
    }

    .newProductChild {
        width: calc(33.33333% - 16px);
        border: none;
        background: none;
        position: relative;
    }

    .rowHeight {
        border: 1px solid black;
        border-left: none;
        width: 25px;
    }

    .pdfBtn,
    .btnExport {
        padding: 10px 20px;
        font-size: 18px;
        font-weight: bold;
        border: none;
        background-color: steelblue;
        color: white;
        margin-bottom: 10px;
        border-radius: 5px;
    }

    .login_btn {
        border: none;
        padding: 10px 50px;
        font-weight: 600;
        font-size: 16px;
        background-color: #4272d7;
        color: white;
        border-radius: 25px;
    }

    .login_parent {
        width: 400px;
        background-color: #fff;
        text-align: center;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 100px 30px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .input_login {
        height: 50px;
        width: 100%;
        border: none;
    }

    .btnsParent {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin-top: 25px;
    }

    .popup_delete,
    .popup_close {
        border: none;
        padding: 5px 10px;
    }

    .rowParent img:hover {
        cursor: pointer;
    }

    .outofstock {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    .undoredo {
        border: none;
        padding: 10px 50px;
        font-weight: 600;
        background-color: steelblue;
        color: white;
        border-radius: 5px;
    }

    .shelfImageWrapper.active {
        display: flex;
        flex-direction: column;
        align-items: center;
        place-content: center;
        background-color: #F2F6FA;
    }

    .fullscreenParent {
        padding: 10px 10px;
        background-color: white;
        border: 1px solid #C0C0C0;
        border-radius: 5px;
        width: fit-content;
        cursor: pointer;
    }

    .zoomParent {
        display: flex;
        padding: 10px 10px;
        background-color: white;
        border: 1px solid #C0C0C0;
        border-radius: 5px;
        width: fit-content;
        align-items: self-end;
        cursor: pointer;
    }

    .actionsParent {
        display: flex;
        align-items: center;
        column-gap: 10px;
        justify-content: flex-end;
        padding-right: 10px;
    }

    .shelfParent .rowParent {
        background-color: #D9D6D6 !important;
        box-shadow:
            0 2.8px 2.2px rgba(0, 0, 0, 0.034),
            0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072),
            0 41.8px 33.4px rgba(0, 0, 0, 0.086),
            0 100px 80px rgba(0, 0, 0, 0.12);
        // background-color: linear-gradient(180deg, #FFFFFF 0%, #DDDDDD 100%);
    }

    .currentlyClicked {
        border: 3px dashed #0570EB;
    }
}

.productProtertiesParent {
    .propertyName {
        font-size: 18px;
        color: #63625D;
        margin-bottom: 8px;
    }

    .categorytitle {
        background-color: #E8E8E8;
        padding: 10px 15px;
    }

    .propertiesWrapper {
        padding: 15px;
    }

    .removebtn {
        background-color: #0570EB;
        padding: 10px 15px 10px 15px;
        border: none;
        color: white;
        border-radius: 5px;
        margin-top: 25px;
    }

    .removebtnWrapper {
        text-align: center;
    }

    .propertyDetails {
        font-size: 18px;
    }

    .hintParent {
        position: absolute;
        bottom: 15px;
        border: 1px solid #0570EB;
        background-color: #E8F4FF;
        width: fit-content;
        padding: 12px;
        border-radius: 6px;
        transform: translateX(20px);
    }

    .hintParent p {
        color: #0570EB;
        font-weight: 500;
    }


    .hintParent img {
        object-fit: contain;
    }

    .bulbParent {
        display: flex;
        column-gap: 15px;
        align-items: flex-start;
    }

    .offcanvas-body {
        padding: 0px !important;
        position: relative;
    }

    .offcanvas-header {
        background-color: #E8E8E8;
        padding: 5px;
        padding-right: 10px;
    }

    .adjustWidth,
    .adjustHeight {
        width: 80px;
        height: 40px;
        border: 1px solid #A2A1A1;
        border-radius: 5px;
    }

    .adjustWidthWrapper,
    .adjustHeightWrapper {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }

    .adjustWidthWrapper p,
    .adjustHeightWrapper p {
        margin-bottom: 0px;
        width: 60px;
        font-size: 16px;
    }

    .selectedRow {
        font-size: 18px;
        margin-bottom: 0px;
    }

    .changeHeightParent {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 15px;
    }

    .applyBtn {
        background: #0570EB;
        border: none;
        color: white;
        padding: 10px 15px;
        border-radius: 5px;
        width: 146px;
    }

    .cancelBtn {
        background: white;
        border: 1px solid #0570EB;
        color: #0570EB;
        padding: 10px 15px;
        border-radius: 5px;
        width: 146px;
    }

    .btnParent {
        display: flex;
        justify-content: center;
        column-gap: 15px;
    }

    .hintParent ul {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
}

.offcanvas-backdrop {
    position: unset !important;
}