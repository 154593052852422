.login_whole {
    .login_btn {
        border: none;
        padding: 10px 50px;
        font-weight: 600;
        font-size: 16px;
        background-color: #4272d7;
        color: white;
        border-radius: 25px;
    }

    .login_parent {
        width: 400px;
        background-color: #fff;
        text-align: center;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 100px 30px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .input_login {
        height: 50px;
        width: 100%;
        border: none;
    }
}